import React from 'react';
import styles from './ColumnRTE.module.scss';

function ColumnRTE({columnContent}) {    
    const markup = {__html: columnContent.html};

    return (
        <div className={styles.container}>
            <div className={styles.rte} dangerouslySetInnerHTML={markup}>
            </div>
        </div>
    )
}

export default ColumnRTE;