import React from 'react';


function LoginButton({setShowModal}) {
    // let protocol = window.location.protocol;
    // let host = window.location.host;

    const _handleClick = () => {
        setShowModal(true);
        return;
    }

    return (
      <button class="login-cta" type="button" onClick={_handleClick}>Login to Register</button>
    )
}

export default LoginButton;