import React from 'react';
import styles from './SocialMediaColumn.module.scss';
import {Desktop} from '../../media-queries/MediaQueries';

import {ReactComponent as FacebookIcon} from '../../icons/facebook-f.svg';
import {ReactComponent as TwitterIcon} from '../../icons/twitter.svg';
import {ReactComponent as InstagramIcon} from '../../icons/instagram.svg';
import {ReactComponent as LinkedInIcon} from '../../icons/linkedin-in.svg';

function SocialMediaColumn({data}) {
    const icons = [
        <FacebookIcon className={styles.icon} />,
        <TwitterIcon className={styles.icon} />,
        <InstagramIcon className={styles.icon} />,
        <LinkedInIcon className={styles.icon} />
    ];
    return (
        <>
            <Desktop>
                <h2 className={styles.title}>FOLLOW</h2>
            </Desktop>
            <div className={styles.wrapper}>
                {data.map((platform, key) => {
                    return (
                        <a key={key} href={platform.path} className={styles.link} target="_blank">
                            {icons[key]}
                        </a>
                    )
                })}
            </div>
        </>
    )
}

export default SocialMediaColumn;