import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Breadcrumb.module.scss'
import {Desktop, Mobile} from '../../media-queries/MediaQueries';

import {ReactComponent as ChevronRightIcon} from '../../icons/chevron-right.svg';
import {ReactComponent as ChevronLeftIcon} from '../../icons/chevron-left.svg';

function Breadcrumb({content}) {
    // These are placeholders until we get the final JSON key names
    const {
        homePageTitle,
        homePageUrl,
        currentPageTitle,
        parentPageUrl,
        parentPageTitle,
        grandparentPageUrl,
        grandparentPageTitle
    } = content;

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <Desktop>
                    <a href={homePageUrl} className={styles.link}>{homePageTitle}</a>
                    {grandparentPageUrl && <ChevronRightIcon className={styles.chevron}/>}
                    <a href={grandparentPageUrl} className={styles.link}>{grandparentPageTitle}</a>
                    {parentPageUrl && <ChevronRightIcon className={styles.chevron}/>}
                    <a href={parentPageUrl} className={styles.link}>{parentPageTitle}</a>
                    {currentPageTitle && <ChevronRightIcon className={styles.chevron}/>}
                    <span className={styles.article}>{currentPageTitle}</span>
                </Desktop>
                <Mobile>
                    <ChevronLeftIcon className={styles.chevron}/>
                    <a href={parentPageUrl || homePageUrl} className={styles.link}>{parentPageTitle || homePageTitle}</a>
                </Mobile>
            </div>
        </div>
    )
}

const mounts = document.querySelectorAll('.breadcrumb-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<Breadcrumb content={content}/>, mount);
    });
}

export default Breadcrumb;