import React, {useEffect, useState} from 'react';
import styles from './MobileHomepageHeroVideo.module.scss';

import ReactPlayer from 'react-player/vimeo';

function MobileHomepageHeroVideo({backgroundVideo, ctaVideo, heroFullPlayer}) {
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        if (heroFullPlayer) {
            setPlaying(true);
        }
    }, [heroFullPlayer])

    if (heroFullPlayer) {
        // Plays in separate window for mobile when button is clicked.
        return (
                <ReactPlayer 
                    className={styles.player}
                    key={1}
                    url={ctaVideo}
                    volume={1}
                    controls={true}
                    playing={playing}
                    playsinline={false}
                    height="100%"
                    width="100%"
                />
        );
    } else {
        // Default is a player that remains hidden.
        // We need this to stay hidden and get switched out for the full-screen player
        // Otherwise the full-screen player does not open in new window.
        return (
                <ReactPlayer
                    className={`${styles.player} ${styles.hidden}`}
                    key={0}
                    url={backgroundVideo}
                    height="100%"
                    width="100"
                />
        )
    }
}

export default MobileHomepageHeroVideo;