import React from 'react';
import styles from './ArticlePromo.module.scss';

import defaultImage from '../../images/no-image.png';

function ArticlePromo() {

  const articleLink = "www.google.com"
  const promoImageLink = defaultImage;

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <a href={articleLink} className={styles.link}>
          <img src={promoImageLink} className={styles.image} alt="Needs description here"/>
        </a>
      </div>
      <div className={styles.contentWrapper}>
        <h5 className={styles.date}>January 1 2021</h5>
        <a href={articleLink} className={styles.link}>
          <h2 className={styles.title}>ARTICLE TITLE HERE</h2>
        </a>
        <p className={styles.description}>DESCRIPTION goes here</p>
        <a href={articleLink}>
          <button className={styles.button}>Button Text</button>
        </a>
      </div>
    </div>
  )
}

export default ArticlePromo;