import React, {useState, useEffect}from 'react';
import ReactDOM from 'react-dom';
import styles from './RelatedArticles.module.scss';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination}from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import {useMediaQuery} from 'react-responsive';
import {Desktop, Mobile} from '../../media-queries/MediaQueries';
import axios from 'axios';

import ArticleGridViewTile from '../Utilities/ArticleGridViewTile';

SwiperCore.use([Pagination]);

function RelatedArticles({content}) {
  const [articles, setArticles] = useState([]);

  let {
    apiUrl, 
    apiSave: apiSaveUrl,
    apiDelete: apiDeleteUrl,
    header, 
    overrides, 
    isAuthenticated
  } = content;
  overrides = overrides.map(item => JSON.parse(item));

  let isAuthenticatedBoolean = isAuthenticated === "true";

  let protocol = window.location.protocol;
  let host = window.location.host;
  let completeApiUrl = `${protocol}//${host}${apiUrl}`;
  let tempApiUrl = `http://localhost:8080/site/content/content-search/search?catFilters=get-prepared&rows=4`

  useEffect(() => {
    if (overrides.length < 4) {
      const fetch = async() => {
        let apiUrlObj = new URL(completeApiUrl);
        apiUrlObj.searchParams.set('currentUrl', window.location.href);
        
        let response = await axios.get(apiUrlObj.href);
        let allArticles = [...overrides, ...response.data.docs].slice(0, 4);
        setArticles(allArticles);
      }
      fetch();
    } else if (overrides.length === 4){
      setArticles(overrides);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className="custom-max-width">
        <h2 className={styles.title}>{header}</h2>
        <Desktop>
          <div className={styles.display}>
            {articles.map((article, key) => {
              return (
                <ArticleGridViewTile 
                  key={key} 
                  apiSaveUrl={apiSaveUrl}
                  apiDeleteUrl={apiDeleteUrl}
                  articleData={article} 
                  isAuthenticated={isAuthenticatedBoolean}/>
              )
            })}
          </div>
        </Desktop>
        <Mobile>
            <Swiper
              id="main"
              className={styles.swiperContainer}
              pagination
              slidesPerView={'auto'}>
              {articles.map((article, key) => {
                return (
                  <SwiperSlide key={key} className={styles.swiperSlide}>
                    <ArticleGridViewTile 
                      articleData={article} 
                      apiSaveUrl={apiSaveUrl}
                      apiDeleteUrl={apiDeleteUrl}
                      inSwiperSlide={true}
                      isAuthenticated={isAuthenticatedBoolean}/>
                  </SwiperSlide>
                )
              })}
            </Swiper>
        </Mobile>
      </div>
    </div>
  )
}

const mounts = document.querySelectorAll('.related-content-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<RelatedArticles content={content}/>, mount);
  });
}

export default RelatedArticles;