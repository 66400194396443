import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './RelatedProducts.module.scss';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination}from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import {Desktop, Mobile} from '../../media-queries/MediaQueries';
import axios from 'axios';

import ProductTile from './ProductTile';

SwiperCore.use([Pagination]);

function RelatedProducts({content}) {
  let {apiUrl, header, overrides} = content;
  overrides = JSON.parse(overrides);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    let myProducts = [];

    const fetchOverrides = async () => {
      for (let i = 0; i < overrides.length; i++) {
        let overrideProduct = await fetch(overrides[i]);
        myProducts.push(overrideProduct[0]);
      }

      if (apiUrl) {
        let defaultProducts = await fetch(apiUrl);
        let newProducts = [...myProducts, ...defaultProducts];
        myProducts = newProducts;
      }
      setProducts(myProducts);
    }

    fetchOverrides();
  }, [])

  const fetch = async(apiUrl) => {
    let response = await axios.get(apiUrl);
    return response.data.response.docs;
  }

  return (
    <div className={styles.container}>
      <div className="custom-max-width">
        <h2 className={styles.title}>{header}</h2>
        <Desktop>
          <div className={styles.wrapper}>
            {products.map((product, key) => {
              return (
                <ProductTile key={key} productData={product}/>
              )
            })}
          </div>
        </Desktop>
        <Mobile>
            <Swiper
              id="main"
              className={styles.swiperContainer}
              pagination
              // spaceBetween={24}
              slidesPerView={'auto'}>
              {products.map((product, key) => {
                return (
                  <SwiperSlide key={key} className={styles.swiperSlide}>
                    <ProductTile productData={product}/>
                  </SwiperSlide>
                )
              })}
            </Swiper>
        </Mobile>
      </div>
    </div>
  )
}

const mounts = document.querySelectorAll('.related-products-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
      let content = JSON.parse(mount.getAttribute("data-cms-content"));
      ReactDOM.render(<RelatedProducts content={content}/>, mount);
  });
}

export default RelatedProducts;