import React from 'react';
import { useRouteMatch, Route, Switch} from 'react-router-dom';
import axios from 'axios';
import './App.scss';

// GLOBAL COMPONENTS
import Header from './components/GlobalHeader/Header';
import Footer from './components/GlobalFooter/Footer';

// PAGE LAYOUT COMPONENTS
import HomePage from './pages/HomePage';
import ArticleDetailPage from './pages/ArticleDetailPage';
import SearchResultsPage from './pages/SearchResultsPage';
import SocialMediaPage from './pages/SocialMediaPage';
import SavedArticlesPage from './pages/SavedArticlesPage';
import CategoryListingPage from './pages/CategoryListingPage';

// ARTICLE DETAIL PAGE COMPONENTS
import ArticleHeader from './components/ArticleDetail/ArticleHeader';
import ArticleRTE from './components/ArticleDetail/ArticleRTE';
import AboutTheAuthor from './components/ArticleDetail/AboutTheAuthor';
import Breadcrumb from './components/ArticleDetail/Breadcrumb';
import LoginModal from './components/ArticleDetail/LoginModal';
import TwoColumn from './components/ArticleDetail/TwoColumn';
import ArticleShare from './components/ArticleDetail/ArticleShare';
import RelatedArticles from './components/ArticleDetail/RelatedArticles';
import RelatedProducts from './components/ArticleDetail/RelatedProducts';

// HOME PAGE COMPONENTS
import HomepageHero from './components/Home/HomepageHero';
import LatestStories from './components/Home/LatestStories';

// CATEGORY LISTING PAGE COMPONENTS
import CategoryListing from './components/CategoryListingTemplate/CategoryListing';

// SAVED ARTICLES PAGE COMPONENTS
import SavedArticles from './components/SavedArticlesTemplate/SavedArticles';
import NoSavedArticles from './components/SavedArticlesTemplate/NoSavedArticles';

// SEARCH RESULTS PAGE COMPONENTS
import SearchResults from './components/SearchResultsTemplate/SearchResults';
import RelatedContent from './components/SearchResultsTemplate/RelatedContent';
import NoResults from './components/SearchResultsTemplate/NoResults';
import ArticleFilter from './components/SearchResultsTemplate/ArticleFilter';

// SOCIAL MEDIA PAGE COMPONENTS
import ArticlePromo from './components/SocialMediaTemplate/ArticlePromo';
import SocialMediaHeader from './components/SocialMediaTemplate/SocialMediaHeader';
import PlatformList from './components/SocialMediaTemplate/PlatformList';

// RIGHT RAIL COMPONENTS
import RRStories from './components/RightRail/RRStories';
import TrendingTopics from './components/RightRail/TrendingTopics';

// PRODUCT CAROUSEL COMPONENTS
import ProductCarousel from './components/ProductCarousel/ProductCarousel';

// EVENT COMPONENTS
import EventsLogin from './components/Events/EventsLogin';
import LoginButton from './components/Events/LoginButton';

// REUSABLE UTILITY COMPONENTS
import ResponsiveImage from './components/Utilities/ResponsiveImage';
import ResponsiveVideo from './components/Utilities/ResponsiveVideo';
import Hero from './components/Utilities/Hero';

// axios.interceptors.request.use(config => ({...config, withCredentials: true}));

function App(props) {
  const configuration = {
    httpClient: axios,
    cmsBaseUrl: process.env.REACT_APP_CMS_BASE_URL,
    // spaBaseUrl: process.env.REACT_APP_SPA_BASE_URL,
    request: {
      path: `${props.location.pathname}${props.location.search}`
    }
  }

  // let { path, url } = useRouteMatch();

  const mapping = {
    "About the author Component": AboutTheAuthor,
    // "Article Header Component": ArticleHeader,
    "Article Images Component": ResponsiveImage,
    // "Breadcrumb Component": Breadcrumb,
    // "Homepage Hero": HomepageHero,
    // "RTE": ArticleRTE,
    "Saved Articles": SavedArticles,
    //"Related Products": RelatedProducts,
    "Social Promo": ArticlePromo,
    "Trending Topics": TrendingTopics,
    // "Two Column Control": TwoColumn,
    // "Video": ResponsiveVideo,
    "Social Header": SocialMediaHeader
  };

  return (
    <>
        {/* <Header /> */}
        <section className="content">
          <Switch>
              <Route exact path="/" component={HomePage}/>
              <Route path="/article" component={ArticleDetailPage}/>
              <Route path="/article:articleId" component={ArticleDetailPage}/>
              <Route path="/social" component = {SocialMediaPage}/>
              <Route path="/saved-articles" component={SavedArticlesPage}/>
              <Route path="/category-listing" component={CategoryListingPage}/>
              <Route path="/search-results" component={SearchResultsPage}/>
          </Switch>
        </section>
        {/* <Footer /> */}
    </>
  );
}

export default App;