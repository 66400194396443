import React from 'react';
import styles from './SearchResultsPage.module.scss';

function SearchResultsPage() {
  return (
    <div className={`custom-max-width ${styles.container}`}>
    </div>
  )
}

export default SearchResultsPage;