import React from 'react';
import styles from './ContactColumn.module.scss';

function ContactColumn({data}) {
    const [address, phoneNumber, email, website] = data;
    const callableNbr = phoneNumber.path.replace(/\./g, '-');
    return (
        <>
            <h2 className={styles.title}>CONTACT</h2>
            <div className={styles.container}>
                <p className={styles.line}>{address.name}</p>
                <a className={`${styles.line} ${styles.link}`} href={callableNbr}>{phoneNumber.name}</a>
                <a className={`${styles.line} ${styles.link}`} href={email.path}>{email.name}</a>
                <a className={`${styles.line} ${styles.link}`} target="_blank" href={website.path}>{website.name}</a>
            </div>
        </>
    )
}

export default ContactColumn;