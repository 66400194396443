import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './ProductCarousel.module.scss';
import axios from 'axios';

import { useMediaQuery } from 'react-responsive';

import ProductSlide from './ProductSlide';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

import { ReactComponent as ChevronRightIcon } from '../../icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../icons/chevron-left.svg';

SwiperCore.use([Navigation, Pagination]);

function ProductCarousel({ content }) {
  // const { header, productsPlaceholder: products } = content;

  let {apiUrl, header, overrides} = content;
  overrides = JSON.parse(overrides);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let myProducts = [];

    const fetchOverrides = async () => {
      for (let i = 0; i < overrides.length; i++) {
        let overrideProduct = await fetch(overrides[i]);
        myProducts.push(overrideProduct[0]);
      }

      if (apiUrl) {
        let defaultProducts = await fetch(apiUrl);
        let newProducts = [...myProducts, ...defaultProducts];
        myProducts = newProducts;
      }
      setProducts(myProducts);
    }

    fetchOverrides();
    setLoading(false);
    console.log(loading);
  }, [])

  const fetch = async(apiUrl) => {
    let response = await axios.get(apiUrl);
    return response.data.response.docs;
  }

  const isDesktopLg = useMediaQuery({ query: `(min-width: 1440px)` });
  const isDesktopSm = useMediaQuery({ query: `(min-width: 1200px)` });
  const isTabletLandscape = useMediaQuery({ query: `(min-width: 1024px)` });
  const isTabletPortrait = useMediaQuery({ query: `(min-width: 768px)` });
  // const isMobileLarge = useMediaQuery({ query: `(min-width: 480px)` });
  // const isMobileSmall = useMediaQuery({ query: `(max-width: 479px)` });
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const slidesPerPage = () => {
    if (isDesktopLg) return 6;
    if (isDesktopSm) return 5;
    if (isTabletLandscape) return 4;
    if (isTabletPortrait) return 3;
    if (isMobile) return 'auto';
  };

  const pagination = {
    clickable: true,
  };

  const navigation = {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  };

  return (
    <div className={styles.container}>
      <div className={`custom-max-width ${styles.contentWrapper}`}>
        <h2 className={styles.header}>{header}</h2>
        <Swiper
          slidesPerView={slidesPerPage()}
          pagination={pagination}
          navigation={navigation}
          className={styles.swiperContainer}
        >
          {products.map((product, key) => {
            return (
              <SwiperSlide className={styles.swiperSlide} key={key}>
                <ProductSlide productData={product} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className='swiper-button-prev'>
          <ChevronLeftIcon className={styles.chevron} />
        </div>
        <div className='swiper-button-next'>
          <ChevronRightIcon className={styles.chevron} />
        </div>
      </div>
    </div>
  );
}

const mounts = document.querySelectorAll('.product-carousel-mount');

if (mounts !== null) {
  mounts.forEach((mount) => {
    let content = JSON.parse(mount.getAttribute('data-cms-content'));
    ReactDOM.render(<ProductCarousel content={content} />, mount);
  });
}

export default ProductCarousel;
