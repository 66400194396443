import React from 'react';
import styles from './RelatedContent.module.scss';

function RelatedContent() {
  return  (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>search keyword related content</h1>
        <p className={styles.subTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <button className={styles.ctaBtn}>learn more</button>
      </div>
    </div>
  )
}

export default RelatedContent;