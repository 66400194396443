import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './ResponsiveVideo.module.scss';
import {useMediaQuery} from 'react-responsive';

import ReactPlayer from 'react-player/vimeo';
import VideoPlayIcon from './VideoPlayIcon';

function ResponsiveVideo({content}) {
    const [playing, setPlaying] = useState(false);
    const [playsInline, setPlaysInline] = useState(true);
    const isDesktop = useMediaQuery({ minWidth: 1200 });

    const setAutoFullScreen = () => {
        if (!isDesktop) {
            setPlaysInline(false);
        }
        setPlaying(true);
    }

    const {
        videoURL,
        imagelink: imageURL
    } = content;

    return (
        <div className={styles.container}>
            <ReactPlayer 
                className={styles.player}
                url={videoURL}
                controls={true}
                light={imageURL ? imageURL : true}
                playsinline={playsInline}
                onClickPreview={setAutoFullScreen}
                playing={playing}
                playIcon={<VideoPlayIcon/>}
                height="100%"
                width="100%"
            />
        </div>
    )
}

const mounts = document.querySelectorAll('.video-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<ResponsiveVideo content={content}/>, mount);
    });
}

export default ResponsiveVideo;