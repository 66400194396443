import React from 'react';
import ReactDOM from 'react-dom';
import styles from './TwoColumn.module.scss'

import ColumnRTE from './ColumnRTE';
import ResponsiveImage from '../Utilities/ResponsiveImage';

function TwoColumn({content}) {
    const {
        leftDocument,
        rightDocument
    } = content;
    if (!leftDocument || !rightDocument) return null;

    const renderColumn = (jsonStringDoc) => {
        let jsonDoc = JSON.parse(jsonStringDoc);
        switch(jsonDoc.docType) {
            case "resourcecenterbrx:RTE":
                return (
                    <ColumnRTE columnContent={jsonDoc.data}/>
                );
            case "resourcecenterbrx:ImageAndText":
                return (
                    <ResponsiveImage columnContent={jsonDoc.data}/>
                )
        }
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.column} ${styles.leftColumn}`}>
                {renderColumn(leftDocument)}
            </div>
            <div className={`${styles.column}`}>
                {renderColumn(rightDocument)}
            </div>
        </div>
    )
}

const mounts = document.querySelectorAll('.two-column-control-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<TwoColumn content={content}/>, mount);
    });
}

export default TwoColumn;