import React from 'react';
import styles from './SocialMediaPage.module.scss';

import SocialMediaHeader from '../components/SocialMediaTemplate/SocialMediaHeader';
import ArticlePromo from '../components/SocialMediaTemplate/ArticlePromo';
// import PlatformList from '../components/SocialMediaTemplate/PlatformList';

function SocialMediaPage() {
    return(
        <div className={`custom-max-width ${styles.container}`}>
            <SocialMediaHeader />
            <ArticlePromo />
        </div>
    )
}

export default SocialMediaPage;