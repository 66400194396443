import React from 'react';
import styles from './AboutTheAuthor.module.scss'
import {BrComponentContext, BrPageContext, BrManageContentButton} from '@bloomreach/react-sdk';

function AboutTheAuthor() {
    const page = React.useContext(BrPageContext);
    const component = React.useContext(BrComponentContext);

    const {document: documentRef} = component.getModels();
    const document = documentRef && page.getContent(documentRef);

    if (!document) return null;

    const {
        imagelink: imageRef,
        text,
        string,
    } = document.getData();

    const image = imageRef && page.getContent(imageRef);
    const imageUrl = image && image.getUrl();

    return (
        <div className={styles.container}>
            <div className={styles.editButton}>
                <BrManageContentButton content={document}/>
            </div>
            <div className={styles.wrapper}>
                {
                    imageUrl &&
                    <img className={styles.image} src={imageUrl} alt={image.description}/>
                }
                <div className={styles.textWrapper}>
                    <h3 className={styles.title}>{string}</h3>
                    <p className={styles.text}>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutTheAuthor;