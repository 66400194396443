import React, {useState, useRef, useEffect} from 'react';
import styles from './SearchBar.module.scss';
import axios from 'axios';

import {ReactComponent as SearchIcon} from '../../icons/search.svg';
import {ReactComponent as CloseIcon} from '../../icons/X.svg';

function SearchBar({searchPath, autosuggestUrl, searchResultsPageView, placeholderText, pixelProps}) {
    const [searchTerm, setSearchTerm] = useState("");
    const inputEl = useRef(null);
    const wrapperRef = useRef(null);
    
    const [suggestionList, setSuggestionList] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        inputEl.current.focus();
        
        let params = new URLSearchParams(window.location.search);
        if (searchResultsPageView && params.has("q")) {
            setSearchTerm(params.get("q"));
        }
        
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchResultsPageView])

    const _handleChange = (e) => {
        setSearchTerm(e.target.value);

        if (e.target.value && e.target.value.length > 2) {
            const fetch = async() => {
                let response = await axios.get(autosuggestUrl + "&q=" + e.target.value);
                if (response && response.data && response.data.suggestionGroups) {
                    let tempSugList = [];
                    for (var i = 0; i < response.data.suggestionGroups.length; i++) {
                        let tempSugGroup = response.data.suggestionGroups[i];
                        if (tempSugGroup.querySuggestions) {
                            tempSugList = tempSugList.concat(tempSugGroup.querySuggestions);
                        }
                    }

                    if (tempSugList.length > 0) {
                        if (tempSugList.length > 5) {
                            tempSugList = tempSugList.slice(0, 5);
                        }
                        setSuggestionList(tempSugList);
                        setShowSuggestions(true);
                    } else {
                        setSuggestionList([]);
                        setShowSuggestions(false);
                    }
                }
            }
            fetch();
        } else {
            setSuggestionList([]);
            setShowSuggestions(false);
        }
    }
    
    const _runSearch = (e, searchTermToUse) => {

        // Take the text from input element that's stored in state
        // Add it as a query parameter to the provided searchPath
        // Change our current location to the new URL
        let protocol = window.location.protocol;
        let host = window.location.host;
        let completeAPIUrl = `${protocol}//${host}${searchPath}`;
        let redirectURL = new URL(completeAPIUrl);
        redirectURL.searchParams.set('q', searchTermToUse);

        window.location.href = redirectURL.href;
    }

    const _handleSearch = (e) => {
        e.preventDefault();
        
        // Plug values into BR Search Event Pixel
        let searchData = {};
        searchData["q"] = searchTerm;
        searchData["catalogs"] = [{ "name" : pixelProps.catalog }];
        window.BrTrk.getTracker().logEvent("suggest", "submit", searchData, {}, true);

        _runSearch(e, searchTerm)
    }
    
    const _handleSuggestionClick = (e, suggestion) => {
        // Plug values into BR Suggest Event Pixel
        let searchData = {};
        searchData["aq"] = searchTerm;
        searchData["q"] = suggestion;
        searchData["catalogs"] = [{ "name" : pixelProps.catalog }];
        window.BrTrk.getTracker().logEvent("suggest","click", searchData, {},true);

        setSearchTerm(suggestion);
        // console.log(suggestion + " " + searchTerm);
        setSuggestionList([]);
        setShowSuggestions(false);
        _runSearch(e, suggestion);
    }
    
    const _handleFocus = (e) => {
        if (e.target.value && e.target.value.length > 0 && suggestionList.length > 0) {
            setShowSuggestions(true);
        }
    }

    const _handleClear = () => {
        setSearchTerm('');
        setSuggestionList([]);
        setShowSuggestions(false);
    }

    return (
        <div className={`${styles.container}`}>
            <div className={`custom-max-width ${styles.wrapper}`} ref={wrapperRef}>
                <form action={searchPath} className={`${styles.form}`} onSubmit={_handleSearch}>
                    {
                        searchResultsPageView &&
                        <button className={`${styles.searchResultsButton}`} onClick={_handleSearch}>
                            <SearchIcon className={styles.icon}/>
                        </button>
                    }
                    <input 
                        className={`${styles.input} ${searchResultsPageView ? styles.searchResultsInput : ''}`} 
                        type="text" 
                        ref={inputEl}
                        value={searchTerm}
                        onChange={_handleChange}
                        onFocus={_handleFocus}
                        placeholder={placeholderText} />
                    {
                        !searchResultsPageView &&
                        <button className={styles.searchButton} onClick={_handleSearch}>
                            <SearchIcon className={styles.icon}/>
                        </button>
                    }
                    {
                        searchResultsPageView && searchTerm.length ?
                        <button className={styles.searchButton} onClick={_handleClear}>
                            <CloseIcon className={styles.icon}/>
                        </button>
                        : null
                    }
                    {
                        showSuggestions && suggestionList ?
                        <div className={`${styles.autoSuggestWrapper}`}>
                            <ul>
                                {suggestionList.map(function(suggestion, index){
                                    return <li key={suggestion.query}><span data-key={suggestion.query} onClick={(e) => _handleSuggestionClick(e, suggestion.query)}>{suggestion.displayText}</span></li>;
                                })}
                            </ul>
                        </div>
                        : null
                    }
                </form>
            </div>
        </div>
    )
}

export default SearchBar;