import React from 'react';
import styles from './SavedArticlesHeader.module.scss';

function SavedArticlesHeader({heading, subtitle}) {
  return (
    <div className={styles.container}>
        <h1 className={styles.title}>{heading}</h1>
        <div className={styles.subtitle} dangerouslySetInnerHTML={{__html: subtitle}}></div>
    </div>
  )
}

export default SavedArticlesHeader;