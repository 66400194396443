import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './ArticleHeader.module.scss';

import ArticleHeaderVideo from './ArticleHeaderVideo';
import SocialMediaShare from '../Utilities/SocialMediaShare';
import SaveIcon from '../Utilities/SaveIcon';
import LoginModal from './LoginModal';

function ArticleHeader({content}) {
    const [showModal, setShowModal] = useState(false);

    const {
        apiSave: apiSaveUrl,
        apiDelete: apiDeleteUrl,
        date,
        description,
        imagelink: imageURL,
        parentPageTitle,
        saved,
        title,
        video,
        tags,
        uuid,
        isAuthenticated,
        login_createAccountLink,
        login_createAccountText,
        login_description,
        login_heading,
        login_loginLink,
        login_loginText,
        tagsJson
    } = content;

    const loginModalContent = {
        login_createAccountLink, 
        login_createAccountText, 
        login_description,
        login_heading,
        login_loginLink,
        login_loginText
    };

    let isSavedBoolean = saved === "true";
    let isAuthenticatedBoolean = isAuthenticated === "true";

    const renderMedia = () => {
        if (video) {
            return (
                <div className={styles.videoWrapper}>
                    <ArticleHeaderVideo propVideoURL={video} propImageURL={imageURL}/>
                </div>
            )
        } else if (imageURL) {
            return (
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={imageURL} />
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.dateWrapper}>
                    <h3 className={styles.date}>{date}</h3>
                    <SaveIcon 
                        apiSaveUrl={apiSaveUrl}
                        apiDeleteUrl={apiDeleteUrl}
                        isAuthenticated={isAuthenticatedBoolean} 
                        isSaved={isSavedBoolean} 
                        setShowModal={setShowModal} 
                        title={title}
                        uuid={uuid}/>
                </div>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.topicsWrapper}>
                    {tagsJson.map((category, key) => {
                        if (key < tagsJson.length - 1) {
                            category.categoryName += ",";
                        }
                        return (
                            <a key={key} href={category.categoryPageLink} className={styles.topic}>{category.categoryName}</a>
                        )
                    })}
                </div>
                <p className={styles.description}>{description}</p>
                <div className={styles.socialWrapper}>
                    <SocialMediaShare />
                </div>
                {renderMedia()}
            </div>
            <LoginModal content={loginModalContent} showModal={showModal} setShowModal={setShowModal}/>
        </>
    )
}

const mounts = document.querySelectorAll('.article-header-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<ArticleHeader content={content}/>, mount);
    });
}

export default ArticleHeader;