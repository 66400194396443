import React from 'react';
import styles from './ProductTile.module.scss';
import {ReactComponent as StarIcon} from '../../icons/star.1.svg';

function ProductTile({productData}) {
  const {
    description,
    pcs_is_fs,
    pcs_num_ratings,
    pcs_rating,
    pid,
    price,
    thumb_image,
    title,
    url
  } = productData;


  function renderStars() {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < pcs_rating) {
        stars.push(<StarIcon key={i} className={`${styles.starIcon} ${styles.fill}`}/>)
      } else {
        stars.push(<StarIcon key={i} className={styles.starIcon}/>)
      }
    }
    return stars;
  }

  let truncTitle = title.substr(0, 65) + " \u2026";

  return (
    <div className={styles.tileContainer}>
      <a href={url} target="_blank" className={styles.tileLink}>
        <div className={styles.product}>
          <div className={styles.imageWrapper}>
            <img className={styles.image} src={thumb_image} alt={title}></img>
          </div>
          <div className={styles.titleWrapper}>
            <h4 className={styles.productName}>{truncTitle}</h4>
          </div>
          <div className={styles.ratingsWrapper}>
            <div className={styles.productRating}>{renderStars()}</div>
            <p className={styles.reviewCount}>{pcs_num_ratings}</p>
          </div>
          <p className={styles.productPrice}>${price.toFixed(2)}</p>
          {
            pcs_is_fs === "Yes" && <p className={styles.shippingText}>free shipping</p>
          }
        </div>
      </a>
    </div>
  )
}

export default ProductTile;