import React from 'react';
import styles from './UndoSaveFlyout.module.scss';
import axios from 'axios';

import {ReactComponent as CloseIcon} from '../../icons/X.svg';

function UndoSaveFlyout({apiSaveUrl, flyoutOpen, setFlyoutOpen, lastRemovedArticle, setLastRemovedArticle, saveArticleLocally}) {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let completeSaveApiUrl = `${protocol}//${host}${apiSaveUrl}`;

    const undoDelete = async () => {
        const response = await axios.get(`${completeSaveApiUrl}?id=${lastRemovedArticle.uuid}`);

        if (response.data.status === "success") {
            saveArticleLocally(lastRemovedArticle);
            setLastRemovedArticle({});
            setFlyoutOpen(false);
        }
    }

    return (
        <div className={`${styles.container} ${flyoutOpen ? styles.show : ''}`}>
            <p className={styles.text}>Removed from your Saved Articles</p>
            <div className={styles.options}>
                <p className={styles.undo} onClick={undoDelete}>Undo</p>
                <CloseIcon className={styles.close} onClick={() => setFlyoutOpen(false)}/>
            </div>
        </div>
    )
}

export default UndoSaveFlyout;