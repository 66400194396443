import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import LoginModal from '../ArticleDetail/LoginModal';
import LoginButton from './LoginButton';

function EventsLogin({ content }) {
  const [showModal, setShowModal] = useState(false);

  const {
    isAuthenticated,
    login_createAccountLink,
    login_createAccountText,
    login_description,
    login_heading,
    login_loginLink,
    login_loginText,
  } = content;

  const loginModalContent = {
    login_createAccountLink,
    login_createAccountText,
    login_description,
    login_heading,
    login_loginLink,
    login_loginText,
  };

  return (
    <>
      <LoginButton isAuthenticated={isAuthenticated} setShowModal={setShowModal} />
      <LoginModal content={loginModalContent} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}

const mounts = document.querySelectorAll('.event-login-button-mount');

if (mounts !== null) {
  mounts.forEach((mount) => {
    let content = JSON.parse(document.querySelector('.event-page-header-mount').getAttribute('data-cms-content'));
    ReactDOM.render(<EventsLogin content={content} />, mount);
  });
}

export default EventsLogin;
