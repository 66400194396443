import React from 'react';
import styles from './VideoPlayIcon.module.scss';
import {ReactComponent as PlayIcon} from '../../icons/play.svg';

function VideoPlayIcon() {
    return (
        <div className={styles.button}>
            <PlayIcon className={styles.icon}/>
        </div>
    )
}

export default VideoPlayIcon;