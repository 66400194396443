import React, {useState} from 'react';
import styles from './ArticleHeaderVideo.module.scss';
import {useMediaQuery} from 'react-responsive';

import ReactPlayer from 'react-player/vimeo';
import VideoPlayIcon from '../Utilities/VideoPlayIcon';

function ArticleHeaderVideo({propVideoURL, propImageURL}) {
    const [playing, setPlaying] = useState(false);
    const [playsInline, setPlaysInline] = useState(true);
    const isDesktop = useMediaQuery({ minWidth: 1200 });

    const setAutoFullScreen = () => {
        if (!isDesktop) {
            setPlaysInline(false);
        }
        setPlaying(true);
    }

    return (
        <ReactPlayer 
            className={styles.player}
            url={propVideoURL}
            controls={true}
            light={propImageURL ? propImageURL : true}
            playsinline={playsInline}
            onClickPreview={setAutoFullScreen}
            playing={playing}
            playIcon={<VideoPlayIcon/>}
            height="100%"
            width="100%"
        />
    );
}

export default ArticleHeaderVideo;