import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './ArticleShare.module.scss';
import SocialMediaShare from '../Utilities/SocialMediaShare';
import SaveIcon from '../Utilities/SaveIcon';
import LoginModal from './LoginModal';

function ArticleShare({content}) {
  const [showModal, setShowModal] = useState(false);

  const {
    apiSave: apiSaveUrl,
    apiDelete: apiDeleteUrl,
    isAuthenticated,
    saved,
    uuid,
    login_createAccountLink,
    login_createAccountText,
    login_description,
    login_heading,
    login_loginLink,
    login_loginText
  } = content;

  const loginModalContent = {
    login_createAccountLink,
    login_createAccountText,
    login_description,
    login_heading,
    login_loginLink,
    login_loginText
  };

  let isSavedBoolean = saved === "true";
  let isAuthenticatedBoolean = isAuthenticated === "true";

  return (
    <>
      <div className={styles.container}>
        <SocialMediaShare />
        <SaveIcon
          apiSaveUrl={apiSaveUrl}
          apiDeleteUrl={apiDeleteUrl}
          isAuthenticated={isAuthenticatedBoolean}
          isSaved={isSavedBoolean}
          setShowModal={setShowModal}
          uuid={uuid}
        />
      </div>
      <LoginModal content={loginModalContent} showModal={showModal} setShowModal={setShowModal}/>
    </>
  )
}

const mounts = document.querySelectorAll('.social-share-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<ArticleShare content={content}/>, mount);
    });
}

export default ArticleShare;