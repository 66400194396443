import React from 'react';
import Modal from 'react-modal';
import styles from './LoginModal.module.scss';

import {ReactComponent as ExitIcon} from '../../icons/X.svg';

Modal.setAppElement('#root');
function LoginModal({content, showModal, setShowModal}) {
  const {
    login_createAccountLink,
    login_createAccountText,
    login_description,
    login_heading,
    login_loginLink,
    login_loginText
  } = content;

  const modalStyle = {
    overlay: {
      "backgroundColor": "rgba(33,41,52,0.6)",
      "zIndex": "9999"
    }
  }

  return (
      <Modal
        isOpen={showModal}
        className={styles.content}
        style={modalStyle}>
          <div className={styles.modal} >
            <div className={styles.iconWrapper}>
              <ExitIcon className={styles.icon} onClick={() => setShowModal(false)} />
            </div>
            <div className={styles.textWrapper}>
              <h3 className={styles.modalHeader}>{login_heading}</h3>
              <p className={styles.modalText}>{login_description}</p>
            </div>
            <div className={styles.buttonWrapper}>
              <a href={login_createAccountLink}>
                <button className={styles.loginRedButton}>{login_createAccountText}</button>
              </a>
              <a href={login_loginLink}>
                <button className={styles.loginWhiteButton}>{login_loginText}</button>
              </a>
            </div>
          </div>
      </Modal>
  )
}

export default LoginModal;