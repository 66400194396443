import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './PlatformList.module.scss';
import axios from 'axios';
import {Desktop, Mobile} from '../../media-queries/MediaQueries';

import {useMediaQuery} from 'react-responsive';

import PlatformPost from './PlatformPost';
import {ReactComponent as ChevronRight} from '../../icons/chevron-right.svg';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import {ReactComponent as FacebookIcon} from '../../icons/facebook-f.svg';
import {ReactComponent as TwitterIcon} from '../../icons/twitter.svg';
import {ReactComponent as InstagramIcon} from '../../icons/instagram.svg';
import {ReactComponent as LinkedInIcon} from '../../icons/linkedin-in.svg';


SwiperCore.use([Pagination]);

function PlatformList({content}) {
    let {
        linkURL,
        title,
        viewLinkText,
        platform,
        api_key,
        platform_feed_id
    } = content;

    platform = platform.toLowerCase();

    const [posts, setPosts] = useState([]);

    const fetchFeedIcon = (platform) => {
        switch(platform) {
            case "facebook":
                return <FacebookIcon className={styles.icon}/>
            case "twitter":
                return <TwitterIcon className={styles.icon}/>
            case "instagram":
                return <InstagramIcon className={styles.icon}/>
            case "linkedin":
                return <LinkedInIcon className={styles.icon}/>
        }
    }

    useEffect(() => {
        const fetch = async () => {
            let response = await axios.get(`https://api.curator.io/v1/feeds/${platform_feed_id}/?api_key=${api_key}&limit=4`);
            setPosts(response.data.posts);
        }
        fetch();
    }, []);

    const isTabletLandscape = useMediaQuery({query: `(min-width: 1024px)`});
    const isTabletPortrait = useMediaQuery({query: `(min-width: 768px)`});
    const isMobileLarge = useMediaQuery({query: `(min-width: 480px)`});
    const isMobileSmall = useMediaQuery({query: `(max-width: 479px)`})

    const slidesPerPage = () => {
        if (isTabletLandscape) return 3;
        if (isTabletPortrait) return 2;
        if (isMobileLarge) return 1.5;
        if (isMobileSmall) return 1.25;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}>{title}</h2>
                </div>
                <a className={styles.link} href={linkURL}>
                    <Desktop>
                        <span>{viewLinkText}</span>
                    </Desktop>
                    <Mobile>
                        <div className={styles.iconWrapper}>
                            {fetchFeedIcon(platform)}
                        </div>
                    </Mobile>
                    <ChevronRight className={styles.chevron}/>
                </a>
            </div>
            <div className={styles.posts}>
                <Desktop>
                    {posts.map((post, key) => <PlatformPost key={key} postData={post} platform={platform}/>)}
                </Desktop>
                <Mobile>
                    <Swiper
                        slidesPerView={slidesPerPage()}
                        spaceBetween={40}
                        pagination
                    >
                        {posts.map((post, key) => {
                            return (
                                <SwiperSlide key={key} style={{paddingBottom: '50px'}}>
                                    <PlatformPost postData={post} platform={platform}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Mobile>
            </div>
        </div>
    )
}

const mounts = document.querySelectorAll('.social-platform-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<PlatformList content={content}/>, mount);
    });
}

export default PlatformList;