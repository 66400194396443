import React from 'react';
import ReactDOM from 'react-dom';
import styles from './TrendingTopics.module.scss';

function TrendingTopics({content}) {
  let {categories, title} = content;
  categories = JSON.parse(categories);

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{title}</h3>
      <ul>
        {categories && categories.map((topic, key) => {
          return (
            <li key={key} className={styles.topic}>
              <a href={topic.path} className={styles.topicLink}>{topic.name}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const mounts = document.querySelectorAll('.topics-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<TrendingTopics content={content}/>, mount);
  });
}

export default TrendingTopics;