import React from 'react';
import styles from './ArticleGridViewTile.module.scss';
import defaultImage from '../../images/no-image.png';

import {ReactComponent as VideoIcon} from '../../icons/video.svg';

import SaveIcon from './SaveIcon';
import UnsaveIcon from './UnsaveIcon';

function ArticleGridViewTile({
  articleData, 
  isAuthenticated, 
  inSwiperSlide,
  savedArticlesView, 
  setFlyoutOpen, 
  setLastRemovedArticle, 
  removeArticleLocally, 
  apiSaveUrl, 
  apiDeleteUrl}) {
  const {
    imagelink,
    hasVideoComponent,
    date,
    description,
    keys,
    title,
    type,
    url,
    uuid,
    saved
  } = articleData;

  let isSavedBoolean = saved === "true";
  let truncatedDescription = description.substr(0, 120) + " \u2026";

  return (
    <div className={`${styles.container} ${inSwiperSlide ? styles.slideView : ''}`}>
      <div className={styles.mediaWrapper}>
        <a href={url}>
          <img src={imagelink ? imagelink : defaultImage} className={styles.image} alt={description}></img>
        </a>
        {
          hasVideoComponent &&
          <div className={styles.videoIconContainer}>
            <VideoIcon className={styles.videoIcon}/>
          </div>
        }
      </div>
      <h6 className={styles.date}>{date}</h6>
      <div className={styles.textWrapper}>
        <a href={url} className={styles.title}>{title}</a>
        <a href={url} className={styles.description}>{description}</a>
      </div>
      <div className={styles.metadata}>
        <p className={styles.categories}>
          {keys && keys.map((category, index) => {
            return (
              <a key={index} href={category.pageLink}>{(index ? ', ' : '') + (category.name ? category.name : category.replace(/-/g, ' '))}</a>
            )
          })}
        </p>
        {
          isAuthenticated && !savedArticlesView ?
          <SaveIcon 
            apiSaveUrl={apiSaveUrl}
            apiDeleteUrl={apiDeleteUrl}
            isSaved={isSavedBoolean} 
            isAuthenticated={isAuthenticated} 
            title={title}
            uuid={uuid}/>
          : null
        }
        {
          savedArticlesView ?
          <UnsaveIcon 
            uuid={uuid} 
            apiDeleteUrl={apiDeleteUrl}
            setFlyoutOpen={setFlyoutOpen} 
            articleData={articleData} 
            removeArticleLocally={removeArticleLocally}
            title={title}
            setLastRemovedArticle={setLastRemovedArticle}/>
          : null
        }
      </div>
    </div>
  )
}

export default ArticleGridViewTile;