import React from 'react';
import styles from './ResponsiveImage.module.scss'

function ResponsiveImage({columnContent}) {
    const {
        imagelink: imageURL,
        text,
        string,
    } = columnContent;

    return (
        <div className={`${styles.container} ${columnContent ? styles.columnContainer : ''}`}>
            <img className={styles.image} src={imageURL} alt={string}/>
            {
                text &&
                <p className={styles.caption}>{text}</p>
            }
        </div>
    )
}

export default ResponsiveImage;