import React from 'react';
import styles from './PlatformPost.module.scss';

function PlatformPost({postData, platform}) {
    let {
        image,
        image_large,
        text,
        url,
    } = postData;

    const fetchHashtagUrl = (platform) => {
        switch(platform) {
            case "facebook":
                return `https://www.facebook.com/search/top/?q=%23`;
            case "twitter":
                return `https://twitter.com/search?q=%23`;
            case "instagram":
                return `https://www.instagram.com/explore/tags/`;
        }
    }

    // REGEX Replacement to allow styling of text.
    // Replace all hashtags with anchor tags (except LinkedIn as they do not support)
    // Replace all bitly links with anchor tags
    // Replace all valid URL's with anchor tags
    if (platform !== 'linkedin') {
        text = text.replace(/(^|\s)#([A-Za-z\d-]+)/g, `$1<a target="_blank" href=${fetchHashtagUrl(platform)}$2>#$2</a>`);
    } else {
        text = text.replace(/(^|\s)#([A-Za-z\d-]+)/g, `$1<span>#$2</span>`);
    }
    text = text.replace(/(^|\s)(bit.ly(\/\w+))/gi, `$1<a target="_blank" href=https://$2>$2</a>`);

    text = text.replace (/(^|\s)((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig, `$1<a target="_blank" href=$2>$2</a>`)


    const markup = {__html: text};

    const _handleClick = () => {
        window.open(url, "_blank");
    }

    return (
        <div className={styles.container}>
            {
                image_large || image ?
                <div className={styles.mediaWrapper}>
                    <img src={image_large || image} className={styles.image} onClick={_handleClick}/>
                </div>
                : null
            }
            <p className={styles.text} dangerouslySetInnerHTML={markup}></p>
        </div>
    )
}

export default PlatformPost;