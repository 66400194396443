import React from "react";

import Dropdown from './Dropdown';
import styles from './Navigation.module.scss';

import {Desktop, Mobile} from '../../media-queries/MediaQueries';

// ICONS
import {ReactComponent as NewspaperIcon} from '../../icons/newspaper.svg';
import {ReactComponent as BookmarkIcon} from '../../icons/bookmark.1.svg';
import {ReactComponent as CommentIcon} from '../../icons/comment.svg';
import {ReactComponent as UserIcon} from '../../icons/user.1.svg';
import {ReactComponent as GlobeIcon} from '../../icons/globe.svg';
import {ReactComponent as CalendarAltIcon} from '../../icons/calendar-alt-2.svg';
import {ReactComponent as CommentAltIcon} from '../../icons/comments-2.svg';
import {ReactComponent as SearchIcon} from '../../icons/search.svg';
import {ReactComponent as ChevronDownIcon} from '../../icons/chevron-down.svg';
import {ReactComponent as CloseIcon} from '../../icons/X.svg';
import {ReactComponent as ChevronRightIcon} from '../../icons/chevron-right.svg';


function Navigation({ menuItems, dropdownOpen, setDropdownOpen, searchOpen, setSearchOpen, darkCriteriaMet, scrolled, searchResultsPageView, setHovered}) {
    const renderIcons = (icon) => {
      switch(icon) {
        case 'newspaper':
          return <NewspaperIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'bookmark-1':
          return <BookmarkIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'comment':
          return <CommentIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'user-1':
          return <UserIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'globe':
          return <GlobeIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'calendar-alt':
          return <CalendarAltIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        case 'comment-2':
          return <CommentAltIcon alt="Categories" className={`${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>;
        default:
          return <div></div>;
      }
    }

    const _handleOpenDropdown = (key) => {
        // This function is used for onMouseEnter event for Desktop only.
        setDropdownOpen(key);
    }

    const _handleCloseDropdown = () => {
        // This function is used for onMouseLeave event for Desktop only.
        setDropdownOpen(false);
    }

    const _handleToggleDropdown = (key) => {
        // This function is used for onClick event for Mobile only.
        if (dropdownOpen !== key) {
            setDropdownOpen(key);
        } else {
            setDropdownOpen(false);
        }
    }

    const _handleMouseEnter = () => {
        if (setHovered === undefined) return;
        setHovered(true);
    }

    const _handleMouseLeave = () => {
        if (setHovered === undefined) return;
        setHovered(false);
    }

    const _handleSearchClick = () => {
        if (searchResultsPageView === true) return;
        setSearchOpen(!searchOpen);
    }

    return (
        <nav className={styles.container} onMouseEnter={_handleMouseEnter} onMouseLeave={_handleMouseLeave}>
            <ul className={`${styles.wrapper}`}>
                {menuItems.map((item, key) => {
                  if (item.childMenuItems) {
                    return (
                      <>
                        <Desktop>
                            <li className={`${styles.dropdownTab} ${styles.option} ${darkCriteriaMet() ? styles.dark : null}`} onMouseEnter={() => _handleOpenDropdown(key)} onMouseLeave={_handleCloseDropdown}>
                                <a role="button">
                                    <Desktop>{renderIcons(item.icon)}</Desktop>
                                    {item.name}
                                    <ChevronDownIcon alt="Open Categories Tab" className={`${styles.chevron} ${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>
                                </a>
                                {
                                    dropdownOpen === key ?
                                    <Dropdown setDropdownOpen={setDropdownOpen} scrolled={scrolled} dropdownOptions={item.childMenuItems}/>
                                    : null
                                }
                            </li>
                        </Desktop>
                        <Mobile>
                            <li className={`${styles.dropdownTab} ${styles.option} ${darkCriteriaMet() ? styles.dark : null}`} onClick={() => _handleToggleDropdown(key)}>
                                <a role="button">
                                    <Desktop>{renderIcons(item.icon)}</Desktop>
                                    {item.name}
                                    <ChevronDownIcon alt="Open Categories Tab" className={`${styles.chevron} ${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>
                                </a>
                                {
                                    dropdownOpen === key ?
                                    <Dropdown setDropdownOpen={setDropdownOpen} scrolled={scrolled} dropdownOptions={item.childMenuItems}/>
                                    : null
                                }
                            </li>
                        </Mobile>
                      </>
                    )
                  } else {
                      return (
                        <li className={`${styles.option} ${darkCriteriaMet() ? styles.dark : null}`} key={key}>
                            <a href={item.path} target={item.name === 'My Account' ? '_blank' : null}>
                                <Desktop>{renderIcons(item.icon)}</Desktop>
                                {item.name}
                            </a>
                            {
                                key === menuItems.length - 1 ?
                                <ChevronRightIcon className={`${styles.chevron} ${styles.icon} ${darkCriteriaMet() ? styles.dark : null}`}/>
                                : null
                            }
                        </li>
                    )
                  }

                })}
                <Desktop>
                    <div className={`${styles.searchIconBox} ${darkCriteriaMet() ? styles.dark : null}`}>
                            {
                                searchOpen ?
                                <CloseIcon 
                                    alt="Close Searchbar" 
                                    className={`${styles.icon} ${styles.closeIcon} ${darkCriteriaMet() ? styles.dark : null}`}
                                    onClick={_handleSearchClick}/>
                                : <SearchIcon 
                                    alt="Open Searchbar" 
                                    className={`${styles.icon} ${styles.searchIcon} ${darkCriteriaMet() ? styles.dark : null}`}
                                    onClick={_handleSearchClick}/>
                            }
                    </div>
                </Desktop>
            </ul>
        </nav>
    );
};

export default Navigation;