import React from 'react';
import styles from './ProductSlide.module.scss';

function ProductSlide({ productData }) {
  let { title, price, thumb_image, url } = productData;

  let truncTitle = title.substr(0, 46) + " \u2026";

  return (
    <a href={url} className={styles.container}>
      <img src={thumb_image} alt=''></img>
      <div className={styles.title}>{truncTitle}</div>
      <div className={styles.price}>${price.toFixed(2)}</div>
    </a>
  );
}

export default ProductSlide;
