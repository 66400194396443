import React from 'react';
import styles from './SavedArticlesPage.module.scss';
import SavedArticles from '../components/SavedArticlesTemplate/SavedArticles';
import SavedArticlesHeader from '../components/SavedArticlesTemplate/SavedArticlesHeader';

function SavedArticlesPage() {

  return (
    <div className={`custom-max-width ${styles.container}`}>
      <div className={styles.wrapper}>
        <div className={styles.main}></div>
        <div className={styles.rightRail}></div>
      </div>
    </div>
  )
}

export default SavedArticlesPage;
