import React from 'react';
import styles from './CategoryListingPage.module.scss';

function CategoryListingPage() {
    return (
      <div className={`custom-max-width ${styles.container}`}>
        <div className={styles.wrapper}>
          <div className={styles.main}>
          </div>
          <div className={styles.rightRail}>
          </div>
        </div>
      </div>
    )
}

export default CategoryListingPage;