import React from 'react';

import styles from './Dropdown.module.scss';

function Dropdown({setDropdownOpen, scrolled, dropdownOptions}) {
    const _handleOptionClick = (e) => {
        e.stopPropagation();
        setDropdownOpen(false);
    }

    return (
        <div className={`${styles.container} ${scrolled ? styles.scrolled : null}`}>
            <ul className={`${styles.wrapper}`}>
                {dropdownOptions.map((option, key) => {
                    return (
                        <li className={styles.option} key={key} onClick={_handleOptionClick}>
                            <a href={option.path}>{option.name}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Dropdown;