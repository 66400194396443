import React from 'react';
import styles from './ArticleListViewTile.module.scss';
import defaultImage from '../../images/no-image.png';

import {ReactComponent as VideoIcon} from '../../icons/video.svg';
import SaveIcon from './SaveIcon';

function ArticleListViewTile({isAuthenticated, articleData, apiSaveUrl, apiDeleteUrl}) {
  const {
    imagelink,
    date,
    description,
    video,
    keys,
    rating,
    reactionsCount,
    repliesCount,
    title,
    url,
    uuid,
    saved
  } = articleData;

  let isSavedBoolean = saved === "true";
  
  return (
    <div className={styles.container}>
      <div className={styles.mediaWrapper}>
        <a href={url}>
          <img src={imagelink ? imagelink : defaultImage} className={styles.image} alt={description}></img>
        </a>
        {
          video &&
          <div className={styles.videoIconContainer}>
            <VideoIcon className={styles.videoIcon}/>
          </div>
        }
      </div>
      <div className={styles.textWrapper}>
        <h6 className={styles.date}>{date}</h6>
        <a href={url} className={styles.title}>{title}</a>
        <a href={url} className={styles.description}>{description}</a>
        <div className={styles.metadata}>
          <div className={styles.likesRepliesContainer}>
            <h6 className={styles.likes}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z" class="st0"></path></svg>
              <span className="likesNr">{reactionsCount} likes</span>
            </h6>
            <h6 className={styles.replies}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M576 240c0 115-129 208-288 208-48.3 0-93.9-8.6-133.9-23.8-40.3 31.2-89.8 50.3-142.4 55.7-5.2.6-10.2-2.8-11.5-7.7-1.3-5 2.7-8.1 6.6-11.8 19.3-18.4 42.7-32.8 51.9-94.6C21.9 330.9 0 287.3 0 240 0 125.1 129 32 288 32s288 93.1 288 208z"></path></svg>
              {repliesCount} Replies
            </h6>
            <p className={styles.categories}>
              {keys && keys.map((category, index) => {
                return (
                  <a key={index} href={category.pageLink} className={styles.categoryLink}>{(index > 0 ? ', ' : '') + category.name}</a>
                )
              })}
            </p>
          </div>
          {
            isAuthenticated &&
            <SaveIcon 
              className={styles.saveIcon} 
              isSaved={isSavedBoolean} 
              isAuthenticated={isAuthenticated} 
              apiSaveUrl={apiSaveUrl}
              apiDeleteUrl={apiDeleteUrl}
              title={title}
              uuid={uuid}/>
          }
        </div>
      </div>
    </div>
  )
}

export default ArticleListViewTile;