import React from 'react';
import styles from './HomepageHeroVideo.module.scss';

import ReactPlayer from 'react-player/vimeo';

function HomepageHeroVideo({backgroundVideo, ctaVideo, heroFullPlayer}) {
    if (heroFullPlayer) {
        // Plays inside Homepage Hero container with controls and volume
        return (
                <ReactPlayer 
                    className={styles.player}
                    key={1}
                    url={ctaVideo}
                    volume={1}
                    controls={true}
                    playing={true}
                    height="100%"
                    width="100%"
                />
        );
    } else {
        // Autoplays inside Homepage Hero behind overlay text and gradient
        return (
            <ReactPlayer 
                className={styles.player}
                key={0}
                url={backgroundVideo}
                muted={true}
                playing={true}
                loop={true}
                height="100%"
                width="100%"
            />
        );
    }
}

export default HomepageHeroVideo;