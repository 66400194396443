import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './SearchResults.module.scss';

import ArticleFilter from './ArticleFilter';
import ArticleGridViewTile from '../Utilities/ArticleGridViewTile';
import NoResults from './NoResults';
import Pagination from '../Utilities/Pagination';

import {Desktop} from '../../media-queries/MediaQueries';
import axios from 'axios';

function SearchResults({content}) {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [facets, setFacets] = useState({keys: [], type: []});
  const [showNoResults, setShowNoResults] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(9);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const {
    apiUrl,
    apiSave: apiSaveUrl,
    apiDelete: apiDeleteUrl,
    noResultsSuggestions,
    noResultsTitle,
    isAuthenticated,
    pixelProps
  } = content;

  let isAuthenticatedBoolean = isAuthenticated === "true";

  let protocol = window.location.protocol;
  let host = window.location.host;
  let completeAPIUrl = `${protocol}//${host}${apiUrl}`;
  let tempApiUrl = `https://develop-globalindustrial.onehippo.io/site/content/content-search/search`
  
  const [searchURL, setSearchURL] = useState(completeAPIUrl);

  useEffect(() => {
    let currentURL = new URL(window.location.href);
    let searchQuery = currentURL.searchParams.get('q');

    fetchArticles({type: 'search', value: searchQuery});

    // FOR LOCAL TESTING ONLY
    // fetchArticles({type: 'search', value: ""});
  }, []);
  
  const fetchArticles = async (queryParam) => {
    let searchURLObj = new URL(searchURL);
    
    if (queryParam.type === 'search') {
      searchURLObj.searchParams.set('q', queryParam.value);
      searchURLObj.searchParams.delete('catFilters');
      searchURLObj.searchParams.delete('typeFilters');

      let response = await axios.get(searchURLObj.href);

      // Set showNoResults to true if response has 0 search results
      if (response.data.docs.length === 0) setShowNoResults(true);
      if (showNoResults && response.data.docs.length > 0) setShowNoResults(false);

      if (currentPage > 1) setCurrentPage(1);

      //TESTING LOCALLY ONLY
      // let dupArticles = response.data.docs.slice();
      // let dupArticles2 = dupArticles.slice();
      // setArticles([...response.data.docs, ...dupArticles, ...dupArticles2]);

      setArticles(response.data.docs);
      setFacets(response.data.facet_counts.facet_fields);
      setSearchURL(searchURLObj.href);
      setSearchTerm(queryParam.value);

    } else if (queryParam.type === 'filter') {
      let filterParam;

      if (queryParam.checkbox === 'topic') {
        filterParam = 'catFilters';
      } else if (queryParam.checkbox === 'type') {
        filterParam = 'typeFilters';
      }

      if (queryParam.value === '') {
        searchURLObj.searchParams.delete(filterParam);
      } else {
        searchURLObj.searchParams.set(filterParam, queryParam.value);
      }

      let response = await axios.get(searchURLObj.href);

      // Fire a Virtual Page View Pixel with every filter click
      let br_data = {};
 
      // Global pixel parameters ...,
      br_data.acct_id = pixelProps.accountID; 
      br_data.ptype = pixelProps.ptype;
      br_data.title = pixelProps.title || "";
      br_data.domain_key = ""; 
      br_data.view_id = "";
      br_data.user_id = pixelProps.user_id;
      br_data.tms = "";
      
      // This parameter is required on all Virtual Page Loads
      br_data.orig_ref_url = window.location.href;
      
      window.BrTrk.getTracker().updateBrData(br_data);
      window.BrTrk.getTracker().logPageView();

      // Update state for URL so that search params persist
      // Reset current page to 1 in case user filters while on higher pagination
      // Set new articles to state
      setSearchURL(searchURLObj.href);
      setCurrentPage(1);
      setArticles(response.data.docs);
    }
  }

  const calculateStartNumber = () => {
    return indexOfFirstArticle + 1;
  }

  const calculateEndNumber = () => {
    let diff = Math.abs(currentArticles.length - articlesPerPage);

    if (currentArticles.length === 1) return;

    return `-${(currentPage * articlesPerPage) - diff}`;
  }

  return (
    <div className={styles.container}>
      {
        showNoResults &&
        <NoResults title={noResultsTitle} suggestions={noResultsSuggestions} searchTerm={searchTerm}/>
      }
      {
        !showNoResults &&
        <>
          {
            articles.length === 0 && searchTerm === '' ?
            <h2 className={styles.header}>Loading...</h2>
            :
            <>
              <h2 className={styles.header}>{articles.length} {articles.length === 1 ? 'result' : 'results'} for {searchTerm}</h2>
              <div className={styles.resultsWrapper}>
                <ArticleFilter facets={facets} fetchArticles={fetchArticles} searchTerm={searchTerm}/>
                <div className={styles.gridWrapper}>
                  {
                    articles.length >= articlesPerPage && 
                    <p className={styles.range}>
                      Items {calculateStartNumber()}{calculateEndNumber()} of {articles.length} 
                    </p>
                  }
                  {
                    articles.length < articlesPerPage && articles.length > 0 ?
                    <p className={styles.range}>
                      {articles.length <= 1 ? 'Item' : 'Items'} {articles.length && 1}{calculateEndNumber()} of {articles.length} 
                    </p>
                    : null
                  }
                  {
                    articles.length === 0 &&
                    <p className={styles.range}>
                      No Items
                    </p>
                  }
                  <div className={styles.grid}>
                    {currentArticles.map((article, key) => {
                      return (
                        <ArticleGridViewTile 
                          key={key} 
                          apiSaveUrl={apiSaveUrl}
                          apiDeleteUrl={apiDeleteUrl}
                          articleData={article}  
                          className={styles.articleCard}
                          isAuthenticated={isAuthenticatedBoolean}/>
                      )
                    })}
                  </div>
                  {
                    articles.length > articlesPerPage &&
                    <div className={styles.paginationWrapper}>
                      <Pagination
                        articlesPerPage={articlesPerPage}
                        totalArticles={articles.length}
                        currentPage={currentPage}
                        paginate={paginate}/>
                    </div>
                  }
                </div>
              </div>
            </>
          }
        </>
      }
    </div>
  )
}

const mounts = document.querySelectorAll('.search-results-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<SearchResults content={content}/>, mount);
  });
}

export default SearchResults;