
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './NoSavedArticles.module.scss';

import SavedArticlesHeader from './SavedArticlesHeader';

function NoSavedArticles({content}) {
    let {
        descriptionNoneSaved, 
        heading, 
        rightRailNoneSaved, 
        subheading
    } = content;

    let bookmarkSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="SavedArticlesTemplate_NoSavedArticles_bookmarkIcon"><path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"></path></svg>`;

    descriptionNoneSaved = descriptionNoneSaved.replace('$(bookmarkIcon)', bookmarkSVG);

    let markup = {__html: descriptionNoneSaved};

    return (
        <div className={styles.container}>
            <SavedArticlesHeader heading={heading} subtitle={subheading} />
            <div className={styles.contentBlock} dangerouslySetInnerHTML={markup}></div>
        </div>
    )
}

const mounts = document.querySelectorAll('.no-saved-articles-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<NoSavedArticles content={content}/>, mount);
  });
}

export default NoSavedArticles;