import React from 'react';
import styles from './Pagination.module.scss';
import {ReactComponent as ChevronRightIcon} from '../../icons/chevron-right.svg';
import {ReactComponent as ChevronLeftIcon} from '../../icons/chevron-left.svg';

function Pagination({articlesPerPage, totalArticles, paginate, currentPage}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i)
  }

  const _handleDecrease = () => {
    if (currentPage === 1) return;
    paginate(currentPage - 1);
  }

  const _handleIncrease = () => {
    if (currentPage === pageNumbers[pageNumbers.length - 1]) return;
    paginate(currentPage + 1);
  }

  return (
    <nav>
      <ul className={styles.pagination}>
        <li className={`${styles.pageListItem} ${currentPage === 1 ? styles.disabled : ''}`} onClick={_handleDecrease}>
          <ChevronLeftIcon className={styles.chevron}/>
        </li>
        {pageNumbers.map((number) => {
          return (
            <li 
              key={number} 
              className={`${styles.pageListItem} ${number === currentPage ? styles.current : ''}`} 
              onClick={() => paginate(number)}>
                {number}
            </li>
          )
        })}
        <li className={`${styles.pageListItem} ${currentPage === pageNumbers[pageNumbers.length - 1] ? styles.disabled : ''}`} onClick={_handleIncrease}>
          <ChevronRightIcon className={styles.chevron}/>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination;