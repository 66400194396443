import React from 'react';
import ReactDOM from 'react-dom';
import {Desktop} from '../../media-queries/MediaQueries';

import taglineDarkLogo from '../../images/tagline-logo-two-color.png';
import styles from './Footer.module.scss';

import ExploreColumn from './ExploreColumn';
import ContactColumn from './ContactColumn';
import SocialMediaColumn from './SocialMediaColumn';

import TagManager from 'react-gtm-module';

function Footer({content}) {
    const {
        Contact: contactColData,
        Explore: exploreColData,
        Follow: socialColData,
        copyright,
        home,
        analyticsID
    } = content;

    const columns = [
        <ExploreColumn data={exploreColData}/>, 
        <ContactColumn data={contactColData}/>, 
        <SocialMediaColumn data={socialColData}/>
    ];

    const tagManagerArgs = {
        gtmId: analyticsID
    }

    TagManager.initialize(tagManagerArgs);

    return (
        <>
            <footer className={styles.container}>
                <div className={`${styles.wrapper} custom-max-width`}>
                    <Desktop>
                        <div className={`${styles.column} ${styles.logoColumn}`}>
                            <a href={home.path}>
                                <img src={taglineDarkLogo} alt="Logo" className={styles.logo}/>
                            </a>
                        </div>
                    </Desktop>
                    {columns.map((column, key) => {
                        return (
                            <div className={styles.column} key={key}>
                                {column}
                            </div>
                        )
                    })}
                </div>
                <div className={styles.copyright} dangerouslySetInnerHTML={{__html: copyright}}></div>
            </footer>
        </>
    )
}

const mounts = document.querySelectorAll('.footer-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<Footer content={content}/>, mount);
  });
}

export default Footer;