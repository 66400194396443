import React from 'react';
import styles from './HomePage.module.scss';

function HomePage() {
    return(
        <div className={`custom-max-width ${styles.container}`}>
            <div className={styles.wrapper}>
                <div className={styles.main}>
                    {/* <BrComponent path="main"/> */}
                </div>
                <div className={styles.rightRail}>
                    {/* <BrComponent path="right-rail"/> */}
                </div>
            </div>
        </div>
    )
}

export default HomePage;