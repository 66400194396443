import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './SavedArticles.module.scss';

import SavedArticlesHeader from './SavedArticlesHeader';
import ArticleGridViewTile from '../Utilities/ArticleGridViewTile';
import UndoSaveFlyout from './UndoSaveFlyout';

function SavedArticles({content}) {
  const {
    apiSave: apiSaveUrl,
    apiDelete: apiDeleteUrl,
    heading, 
    savedArticles, 
    subheading
  } = content;

  const [articles, setArticles] = useState(savedArticles);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [lastRemovedArticle, setLastRemovedArticle] = useState({});

  useEffect(() => {
    if (flyoutOpen === true) {
      setTimeout(() => {
        setFlyoutOpen(false);
      }, 10000);
    }
  }, [flyoutOpen]);

  const saveArticleLocally = (articleObj) => {
    let newArticles = [...articles, articleObj];
    setArticles(newArticles);
  }

  const removeArticleLocally = (articleObj) => {
    let newArticles = articles.filter(article => article.uuid !== articleObj.uuid);
    setArticles(newArticles);
  }

  return (
    <div className={styles.container}>
      <SavedArticlesHeader heading={heading} subtitle={subheading}/>
      <div className={styles.wrapper}>
        {articles.map((article, key) => {
          return (
            <ArticleGridViewTile 
              key={key} 
              apiSaveUrl={apiSaveUrl}
              apiDeleteUrl={apiDeleteUrl}
              articleData={article} 
              setFlyoutOpen={setFlyoutOpen}
              setLastRemovedArticle={setLastRemovedArticle}
              removeArticleLocally={removeArticleLocally}
              savedArticlesView={true}/>
          )
        })}
      </div>
      <UndoSaveFlyout 
        apiSaveUrl={apiSaveUrl}
        flyoutOpen={flyoutOpen} 
        setFlyoutOpen={setFlyoutOpen} 
        setLastRemovedArticle={setLastRemovedArticle}
        saveArticleLocally={saveArticleLocally}
        lastRemovedArticle={lastRemovedArticle}/>
    </div>
  )
}

const mounts = document.querySelectorAll('.saved-articles-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<SavedArticles content={content}/>, mount);
  });
}

export default SavedArticles;