import React from 'react';
import styles from './NoResults.module.scss';

function NoResults({title, suggestions, searchTerm}) {
  return (
    <div className={styles.container}>
      {
        searchTerm ?
          <>
            <h2 className={styles.header}>{title} for "{searchTerm}"</h2>
            <div className={styles.suggestionsTitle} dangerouslySetInnerHTML={{__html: suggestions}}></div>
            <ul className={styles.suggestionList}>
              <li className={styles.suggestion}>Make sure all words are spelled correctly</li>
              <li className={styles.suggestion}>Try different keywords</li>
              <li className={styles.suggestion}>Try more general keywords</li>
            </ul>
          </>
          : <h2 className={styles.header}>Please enter one or more search terms in the field above</h2>
      }
    </div>
  )
}

export default NoResults;