import React from 'react';
import styles from './ExploreColumn.module.scss';

import {ReactComponent as ChevronRightIcon} from '../../icons/chevron-right.svg';

function ExploreColumn({data}) {
    return (
        <>
            <h2 className={styles.title}>EXPLORE</h2>
            <ul className={styles.list}>
                {data.map((item, key) => {
                        return (
                            <li className={`${styles.option}`} key={key}>
                                <a href={item.path}>
                                    {item.name}
                                </a>
                                {
                                    key === data.length - 1 ?
                                    <ChevronRightIcon className={`${styles.chevron}`}/>
                                    : null
                                }
                            </li>
                        )
                    })}
            </ul>
        </>
    )
}

export default ExploreColumn;