import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './Header.module.scss'

import {Desktop, Mobile} from '../../media-queries/MediaQueries';

//ICONS
import {ReactComponent as HamburgerIcon} from '../../icons/bars.svg';
import {ReactComponent as CloseIcon} from '../../icons/X.svg';
import {ReactComponent as SearchIcon} from '../../icons/search.svg';

import Navigation from './Navigation';
import SearchBar from './SearchBar';

function Header({content}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchResultsPageView, setSearchResultsPageView] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const {
        blueLogoImage,
        home,
        "menu-items": menuItems,
        pixelProps,
        search,
        searchPlaceholder,
        whiteLogoImage,
        autosuggestUrl
    } = content;

    useEffect(() => {
        window.addEventListener('scroll', _handleScroll);
    });

    useEffect(() => {
        let currentURL = window.location.href;
        if (currentURL.includes(search.path)) {
            setSearchResultsPageView(true);
        }
    }, [])

    const darkCriteriaMet = () => {
        return scrolled || searchOpen || dropdownOpen || searchResultsPageView || hovered;
    }

    const _handleScroll = () => {
        const offset = window.scrollY;
        if (offset >= 50) setScrolled(true);
        else setScrolled(false);
    }

    const _handleClose = () => {
        if (searchOpen) setSearchOpen(false);
        if (mobileMenuOpen) {
            // Required to let the onClick listener that was manually added to body
            // inside of Dropdown's useEffect unmount the component first.
            setTimeout(() => {
                setMobileMenuOpen(false);
            }, 10)
        }
    }

    return (
        <header className={`${styles.container} ${darkCriteriaMet() ? styles.dark : null} ${scrolled ? styles.scrolled : null}`}>
            <div className={`custom-max-width ${styles.wrapper}`}>
                <Mobile>
                    <a href={home.path}>
                        <img src={whiteLogoImage} alt="Logo" className={styles.logo}/>
                    </a>
                </Mobile>
                <Desktop>
                    <a href={home.path}>
                        <img src={darkCriteriaMet() ? whiteLogoImage : blueLogoImage} alt="Logo" className={`${styles.logo} ${scrolled ? styles.scrolled : null}`}/>
                    </a>
                </Desktop>
                <Desktop>
                    <Navigation 
                        setHovered={setHovered}
                        menuItems={menuItems}
                        darkCriteriaMet={darkCriteriaMet}
                        scrolled={scrolled}
                        searchResultsPageView={searchResultsPageView}
                        dropdownOpen={dropdownOpen} 
                        setDropdownOpen={setDropdownOpen}
                        searchOpen={searchOpen}
                        setSearchOpen={setSearchOpen} />
                </Desktop>
                <Mobile>
                        {
                            searchOpen || mobileMenuOpen ?
                            <CloseIcon className={styles.menuIcon} onClick={_handleClose}/>
                            : 
                            <div className={styles.mobileIconWrapper}>
                                {
                                    !searchResultsPageView &&
                                    <SearchIcon className={styles.menuIcon} onClick={() => setSearchOpen(true)}/>
                                }
                                <HamburgerIcon className={styles.menuIcon} onClick={() => setMobileMenuOpen(true)}/>
                            </div>
                        }
                </Mobile>
            </div>
            {
                searchOpen || searchResultsPageView ?
                <SearchBar 
                    searchPath={search.path} 
                    autosuggestUrl={autosuggestUrl}
                    searchResultsPageView={searchResultsPageView}
                    pixelProps={pixelProps}
                    placeholderText={searchPlaceholder} 
                    setSearchOpen={setSearchOpen}/>
                : null
            }
            <Mobile>
                {
                    mobileMenuOpen ?
                    <div className={`${styles.mobileMenu}`}>
                        <div className='custom-max-width'>
                            <Navigation
                                menuItems={menuItems}
                                darkCriteriaMet={darkCriteriaMet}
                                dropdownOpen={dropdownOpen} 
                                setDropdownOpen={setDropdownOpen}
                                searchOpen={searchOpen}
                                setSearchOpen={setSearchOpen} />
                        </div>
                    </div>
                    : null
                }
            </Mobile>
        </header>
    )
}

const mounts = document.querySelectorAll('.header-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<Header content={content}/>, mount);
  });
}

export default Header;