import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './RRStories.module.scss';
import defaultImage from '../../images/no-image.png';
import axios from 'axios';

function RRStories({content}) {
  const [articles, setArticles] = useState([]);

  const {
    apiUrl,
    backgroundColor,
    heading
  } = content

  let protocol = window.location.protocol;
  let host = window.location.host;
  let completeAPIUrl = `${protocol}//${host}${apiUrl}`;

  useEffect(() => {
    const fetch = async() => {
      // let tempApiUrl = `https://develop-globalindustrial.onehippo.io/site/content/content-search/search?rows=6`
      let response = await axios.get(completeAPIUrl);
      setArticles(response.data.docs);
    }
    fetch();
  }, []);

  // const monthNames = ["January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"
  // ];

  return (
    <div className={`${styles.container} ${backgroundColor === 'Light' ? `${styles.light}` : `${styles.dark}`}`}>
      <h2 className={styles.header}>{heading}</h2>
      <div className={styles.articlesWrapper}>
        {articles.map((article, key) => {
          // let [month, date, year] = new Date(article.date).toLocaleDateString("en-US").split("/");

          return (
            <div key={key} className={styles.article}>
              <div className={styles.imageWrapper}>
                <a href={article.url} target="_blank">
                  <img src={article.imagelink ? article.imagelink : defaultImage} className={styles.image}></img>
                </a>
              </div>
              <div className={styles.textWrapper}>
                <a href={article.url} target="_blank" className={styles.link}><h5 className={styles.title}>{article.title}</h5></a>
                {/* <h6 className={styles.date}>{`${monthNames[month - 1]} ${date}, ${year}`}</h6> */}
                <h6 className={styles.date}>{article.date}</h6>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mounts = document.querySelectorAll('.search-right-rail-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<RRStories content={content}/>, mount);
  });
}

export default RRStories;