import React, {useState} from 'react';
import styles from './SaveIcon.module.scss';

import {Desktop, Mobile} from '../../media-queries/MediaQueries';
import axios from 'axios';

import {ReactComponent as BookmarkIcon} from '../../icons/bookmark.1.svg';


function SaveIcon({isAuthenticated, isSaved, setShowModal, uuid, apiSaveUrl, apiDeleteUrl, title}) {
    const [articleSaved, setArticleSaved] = useState(isSaved);

    let protocol = window.location.protocol;
    let host = window.location.host;
    let completeSaveApiUrl = `${protocol}//${host}${apiSaveUrl}`;
    let completeUnsaveApiUrl = `${protocol}//${host}${apiDeleteUrl}`;

    const _handleClick = () => {
        if (!isAuthenticated) {
            setShowModal(true);
            return;
        }

        window.BrTrk.getTracker().logEvent('product', 'quickview', {
            prod_id: uuid,
            prod_name: title || '',
            sku: ''
        });

        if (articleSaved) unsaveArticle();
        else saveArticle();
    }

    const saveArticle = async () => {
        try {
            const response = await axios.get(`${completeSaveApiUrl}?id=${uuid}`);

            if (response.data.status === "success") {
                setArticleSaved(true);
            } else {
                throw new Error("An error occurred while saving your article.");
            }
        } catch (e) {
            window.alert(e);
        }
    }

    const unsaveArticle = async () => {
        try {
            const response = await axios.get(`${completeUnsaveApiUrl}?id=${uuid}`);

            if (response.data.status === "success") {
                setArticleSaved(false);
            } else {
                throw new Error("An error occurred while un-saving your article.");
            }
        } catch(e) {
            window.alert(e);
        }
    }

    return (
        <div className={styles.container} onClick={_handleClick}>
            <Desktop>
                <span className={styles.topTooltip}>{articleSaved ? "Unsave Story" : "Save Story"}</span>
            </Desktop>
            <Mobile>
                <span className={styles.leftTooltip}>{articleSaved ? "Unsave Story": "Save Story"}</span>
            </Mobile>
            <BookmarkIcon className={`${styles.icon} ${articleSaved ? styles.saved : ''}`}/>
        </div>
    )
}

export default SaveIcon;