import React from 'react';
import styles from './SocialMediaHeader.module.scss';

import defaultImage from '../../images/no-image.png';

function SocialMediaHeader() {
  const facebookIconSrc = defaultImage

  const twitterIconSrc = defaultImage

  const instagramIconSrc = defaultImage

  const linkedinIconSrc = defaultImage;

  const facebookPage = "www.facebook.com"
  const twitterPage = "www.twitter.com"
  const instagramPage = "www.instagram.com"
  const linkedinPage = "www.linkedin.com"

  const socialIcons = [
    facebookIconSrc,
    twitterIconSrc,
    instagramIconSrc,
    linkedinIconSrc
  ];
  const socialLinks = [
    facebookPage,
    twitterPage,
    instagramPage,
    linkedinPage
  ];

  const titleMarkup = {__html: "<h1>SOCIAL MEDIA HEADER TITLE</h1>"};
  const subTitleMarkup = {__html: "<p>Social Media Subtitle</p>"};

  return (
    <div className={styles.container}>
      <div className={styles.title} dangerouslySetInnerHTML={titleMarkup}></div>
      <div className={styles.subTitle} dangerouslySetInnerHTML={subTitleMarkup}></div>
      <div className={styles.icons}>
          {socialIcons.map((icon, key) => {
            return (
              <div className={styles.iconContainer} key={key}>
                <a key={key} href={socialLinks[key]} className={styles.socialIcon} target="_blank">
                  <img src={icon} className={styles.icon} alt="social media icon" />
                </a>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default SocialMediaHeader;
