import React, {useState} from 'react';
import styles from './ArticleFilter.module.scss';
import {Mobile} from '../../media-queries/MediaQueries';
import {ReactComponent as MinusIcon} from '../../icons/remove-isolated.svg';
import {ReactComponent as ChevronDownIcon} from '../../icons/chevron-down.svg';

function ArticleFilter({facets, fetchArticles, searchTerm}) {
  const [hideFilters, setHideFilters] = useState(false);
  const [hideTopicFilters, setHideTopicFilters] = useState(false);
  const [hideTypeFilters, setHideTypeFilters] = useState(false);

  const [topics, setTopics] = useState([]);
  const [types, setTypes] = useState([]);

  const [boxesChecked, setBoxesChecked] = useState(false);

  function handleFiltersToggleClick() {
    setHideFilters(!hideFilters);
  }

  function handleTopicToggleClick() {
    setHideTopicFilters(!hideTopicFilters)
  }

  function handleTypeToggleClick() {
    setHideTypeFilters(!hideTypeFilters)
  }

  function handleFilter(e) {
    let query;

    if (e.target.name === 'topic') {
      // Redefine the array that will be used for our search query.
      if (e.target.checked) {
        query = [...topics, e.target.value];
      } else {
        query = topics.filter(option => option !== e.target.value);
      }

      setTopics(query);
      // Build the query string using our array
      // Trigger the API function with the built query string
      fetchArticles({type: 'filter', value: query.join(), checkbox: 'topic'});

    } else if (e.target.name === 'type') {
      if (e.target.checked) {
        query = [...types, e.target.value];
      } else {
        query = types.filter(option => option !== e.target.value);
      }

      setTypes(query);
      fetchArticles({type: 'filter', value: query.join(), checkbox: 'type'});
    }

    if (query.length > 0) {
      setBoxesChecked(true)
    } else {
      setBoxesChecked(false)
    }
  }

  function handleClearFilters() {
    const filters = document.querySelectorAll('.search-results-filter-checkbox');
    filters.forEach(filter => {
      filter.checked = false;
    });
    setTopics([]);
    setTypes([]);
    fetchArticles({type: 'search', value: searchTerm});
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4 className={styles.filterTitle}>filter options</h4>
        <div className={styles.btnContainer}>
          <Mobile>
            <button onClick={handleFiltersToggleClick} className={`${styles.hideBtn} ${styles.actionBtn}`}>{hideFilters ? 'show filters' : 'hide filters'}</button>
          </Mobile>
          <button 
            onClick={(e) => {
              handleClearFilters(e);
              setBoxesChecked(false);
            }}
            className={`${styles.clearBtn} ${styles.actionBtn} ${boxesChecked ? null : styles.disabled}`}
            disabled={boxesChecked === false ? true : ''}
            >
              clear filters
            </button>
        </div>
      </div>
      <div className={`${styles.formSection} ${hideFilters ? `${styles.hide}` : null}`}>
        {hideTopicFilters
        ? <button className={styles.collapseBtn} onClick={handleTopicToggleClick}><ChevronDownIcon className={styles.chevronDownIcon}/></button>
        : <button className={styles.collapseBtn} onClick={handleTopicToggleClick}><MinusIcon className={styles.minusIcon} /></button>}
        <p className={styles.formTitle}>topic</p>
        <form className={`${styles.form} ${hideTopicFilters ? `${styles.hide}` : null}`}>
          {facets.keys.map((topic, key) => {
              return (
                <div key={key} className={styles.option}>
                  <input 
                    className={`${styles.input} search-results-filter-checkbox`} 
                    type="checkbox" 
                    id={`topic${key}`} 
                    name="topic"
                    onClick={handleFilter}
                    value={topic.value}></input>
                  <label className={styles.label} htmlFor={`topic${key}`}>{topic.name}</label>
                </div>
              )
          })}
        </form>
      </div>
      <div className={`${styles.formSection} ${hideFilters ? `${styles.hide}` : null}`}>
        {hideTypeFilters
        ? <button className={styles.collapseBtn} onClick={handleTypeToggleClick}><ChevronDownIcon className={styles.chevronDownIcon}/></button>
        : <button className={styles.collapseBtn} onClick={handleTypeToggleClick}><MinusIcon className={styles.minusIcon} /></button>}
        <p className={styles.formTitle}>type</p>
        <form className={`${styles.form} ${hideTypeFilters ? `${styles.hide}` : null}`}>
          {facets.type.map((type, key) => {
              return (
                <div key={key} className={styles.option}>
                  <input 
                    className={`${styles.input} search-results-filter-checkbox`} 
                    type="checkbox" 
                    id={`type${key}`} 
                    name="type"
                    onClick={handleFilter}
                    value={type.value}></input>
                  <label className={styles.label} htmlFor={`type${key}`}>{type.name}</label>
                </div>
              )
          })}
        </form>
      </div>
    </div>
  )
}

export default ArticleFilter;