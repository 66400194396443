import React from 'react';
import styles from './SocialMediaShare.module.scss'

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from 'react-share';

import {ReactComponent as FacebookIcon} from '../../icons/facebook-f.svg';
import {ReactComponent as TwitterIcon} from '../../icons/twitter.svg';
import {ReactComponent as LinkedInIcon} from '../../icons/linkedin-in.svg';
import {ReactComponent as EmailIcon} from '../../icons/envelope.svg';

function SocialMediaShare() {
    const quote = "Visit GlobalIndustrial.com for more articles like this.";
    const url = String(window.location);

    return (
        <div className={styles.container}>
            <FacebookShareButton url={url} quote={quote} className={styles.button}>
                <FacebookIcon className={styles.icon}/>
            </FacebookShareButton>
            <TwitterShareButton url={url} title={quote} className={styles.button}>
                <TwitterIcon className={styles.icon}/>
            </TwitterShareButton>
            <LinkedinShareButton url={url} className={styles.button}>
                <LinkedInIcon className={styles.icon}/>
            </LinkedinShareButton>
            <EmailShareButton url={url} className={styles.button}>
                <EmailIcon className={styles.icon}/>
            </EmailShareButton>
        </div>
    )
}

export default SocialMediaShare;