import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './CategoryListing.module.scss';

import axios from 'axios';

import ArticleListViewTile from '../Utilities/ArticleListViewTile';

function CategoryListing({content}) {
    const [articles, setArticles] = useState([]);
    const [nextStart, setNextStart] = useState(0);
    const [numFound, setNumFound] = useState(0);

    const {
        apiUrl,
        apiSave: apiSaveUrl,
        apiDelete: apiDeleteUrl,
        title,
        subtitle,
        loadMoreCtaText,
        start,
        isAuthenticated,
    } = content;
    let {rows} = content;

    if (typeof rows === "string") {
        rows = Number(rows);
    }

    let isAuthenticatedBoolean = isAuthenticated === "true";

    let protocol = window.location.protocol;
    let host = window.location.host;
    let completeAPIUrl = `${protocol}//${host}${apiUrl}`;
    let tempApiUrl = `https://develop-globalindustrial.onehippo.io/site/content/content-search/search?catFilters=get-prepared`

    useEffect(() => {
        const fetch = async() => {
            let response = await axios.get(completeAPIUrl);
            setNumFound(response.data.numFound);
            setArticles(response.data.docs);
            setNextStart(rows + start);
        }
        fetch();
    }, []);

    const loadMore = async() => {
        let response = await axios.get(`${completeAPIUrl}&start=${nextStart}`);
        let nextArticles = response.data.docs;
        let newArticles = [...articles, ...nextArticles];
        setNextStart(rows + nextStart)
        setArticles(newArticles);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subTitle}>{subtitle}</p>
            </div>
            {articles.map((article, key) => {
                return (
                    <ArticleListViewTile 
                        key={key}
                        apiSaveUrl={apiSaveUrl}
                        apiDeleteUrl={apiDeleteUrl}
                        articleData={article} 
                        isAuthenticated={isAuthenticatedBoolean}/>
                )
            })}
            {
                numFound > rows && 
                <button className={`${styles.ctaBtn} ${nextStart >= numFound ? styles.hide : null}`} onClick={loadMore}>{loadMoreCtaText}</button>
            }
        </div>
    )
}

const mounts = document.querySelectorAll('.category-listing-mount');

if (mounts !== null) {
    mounts.forEach(mount => {
        let content = JSON.parse(mount.getAttribute("data-cms-content"));
        ReactDOM.render(<CategoryListing content={content}/>, mount);
    });
}

export default CategoryListing;