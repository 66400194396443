import React from 'react';
import styles from './UnsaveIcon.module.scss';

import {Desktop, Mobile} from '../../media-queries/MediaQueries';
import axios from 'axios';

import {ReactComponent as TrashIcon} from '../../icons/trash-alt.1.svg';

function UnsaveIcon({uuid, apiDeleteUrl, setFlyoutOpen, articleData, setLastRemovedArticle, removeArticleLocally, title}) {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let completeUnsaveApiUrl = `${protocol}//${host}${apiDeleteUrl}`;

    const _handleClick = async () => {
        window.BrTrk.getTracker().logEvent('product', 'quickview', {
            prod_id: uuid,
            prod_name: title || '',
            sku: ''
        });

        const response = await axios.get(`${completeUnsaveApiUrl}?id=${uuid}`);

        if (response.data.status === "success") {
            setLastRemovedArticle(articleData);
            setFlyoutOpen(true);
            removeArticleLocally(articleData);
        }
    }

    return (
        <div className={styles.container} onClick={_handleClick}>
            <Desktop>
                <span className={styles.topTooltip}>Remove Story</span>
            </Desktop>
            <Mobile>
                <span className={styles.leftTooltip}>Remove Story</span>
            </Mobile>
            <TrashIcon className={styles.icon}/>
        </div>
    )
}

export default UnsaveIcon;