import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './HomepageHero.module.scss';

import {useMediaQuery} from 'react-responsive';

import HomepageHeroVideo from './HomepageHeroVideo';
import MobileHomepageHeroVideo from './MobileHomepageHeroVideo';

function HomepageHero({content}) {
  const {
    ctaColor,
    ctaText,
    ctaURL,
    gradientOverlay,
    imagelink,
    subtitle,
    title,
    video
  }= content;

  const [fullPlayer, setFullPlayer] = useState(null);

  let isLandscapeSize = useMediaQuery({query: `(min-width: 600px)`});
  let isPortraitSize = useMediaQuery({query: `(max-width: 600px)`});

  const renderImageBackground = () => {
    if (fullPlayer) {
      return {
        backgroundColor: `rgba(0, 45, 80, 0.8)`
      }
    }

    if (!video || isPortraitSize) {
      return {
        backgroundImage: `url(${imagelink})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    }
  }

  const subTitleMarkup = {__html: subtitle};
  const titleMarkup = {__html: title};

  return (
    <div className={styles.container} style={renderImageBackground()}>
      {
        video && isLandscapeSize ?
        <HomepageHeroVideo heroFullPlayer={fullPlayer} ctaVideo={ctaURL} backgroundVideo={video}/>
        : null
      }
      {
        video && isPortraitSize ?
        <MobileHomepageHeroVideo heroFullPlayer={fullPlayer} ctaVideo={ctaURL} backgroundVideo={video}/>
        : null
      }
      <div className={`${styles.overlay} ${gradientOverlay ? styles.gradient : null} ${fullPlayer ? styles.hideOverlay : null}`}>
        <div className={styles.content}>
          <div className={`${styles.heroTitle} ${fullPlayer ? styles.hideContent : null}`} dangerouslySetInnerHTML={titleMarkup}></div>
          <div className={`${styles.subTitle} ${fullPlayer ? styles.hideContent : null}`} dangerouslySetInnerHTML={subTitleMarkup}></div>
          {
            video ?
            <button 
              className={`${ctaColor === 'Red' ? styles.redCta : styles.whiteCta} ${fullPlayer ? styles.hideContent : null}`} 
              onClick={() => setFullPlayer(true)}>
                {ctaText}
            </button>
            :           
            <a href={ctaURL}>
              <button className={ctaColor === 'Red' ? styles.redCta : styles.whiteCta}>{ctaText}</button>
            </a>
          }
        </div>
      </div>
    </div>
  )
}

const mounts = document.querySelectorAll('.homepage-hero-mount');

if (mounts !== null) {
  mounts.forEach(mount => {
    let content = JSON.parse(mount.getAttribute("data-cms-content"));
    ReactDOM.render(<HomepageHero content={content}/>, mount);
  });
}

export default HomepageHero;