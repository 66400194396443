import React from 'react';
import styles from './ArticleDetailPage.module.scss';

function ArticleDetailPage() {
    return (
        <div className={styles.container}>
            <div className={`${styles.breadcrumb} custom-max-width`}>
                {/* <BrComponent path="breadcrumb-full-width"/> */}
            </div>
            <div className={`${styles.wrapper} custom-max-width`}>
                <div className={styles.main}>
                </div>
                <div className={styles.rightRail}>
                    {/* <BrComponent path="right-rail"/> */}
                </div>
            </div>
            <div className={styles.fullWidth}>
                {/* <RelatedProducts productData={products}/>
                <RelatedArticles articleData={articles}/> */}
            </div>
        </div>
    )
}

export default ArticleDetailPage;